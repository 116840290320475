@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wojjqm');
  src:  url('fonts/icomoon.eot?wojjqm#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wojjqm') format('truetype'),
    url('fonts/icomoon.woff?wojjqm') format('woff'),
    url('fonts/icomoon.svg?wojjqm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-twitter:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-facebook2:before {
  content: "\e905";
  color: #1877f2;
}
.icon-instagram2:before {
  content: "\e906";
  color: #e4405f;
}
.icon-linkedin2:before {
  content: "\e907";
  color: #0077b5;
}
.icon-twitter2:before {
  content: "\e908";
}
.icon-youtube2:before {
  content: "\e909";
  color: #f00;
}
