@import './mixins';

//// Start - Font families
@font-face {
    font-family: 'met_regular';
    src: url('fonts/met_regular.otf');
}

@font-face {
    font-family: 'met_medium';
    src: url('fonts/met_medium.otf');
}

@font-face {
    font-family: 'met_semi_bold';
    src: url('fonts/met_semi_bold.otf');
}

@font-face {
    font-family: 'met_bold';
    src: url('fonts/met_bold.otf');
}

//// End - Font families


html,
body {
    font-family: 'met_regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

// Start - Colors
.color--primary {
    color: var(--colorPrimary);
}

.color--secondary {
    color: var(--colorSecondary);
}

.color--highlight {
    color: var(--colorHighlight);
}

.color--link {
    color: var(--colorLink);
}

.background--primary {
    background-color: var(--bgColorPrimary);
}

.background--primaryLighter {
    background-color: var(--bgColorPrimaryLighter) !important;
}

.background--secondary {
    background-color: var(--bgColorSecondary);
}

.background--tertiary {
    background-color: var(--bgColorTertiary);
}

.border__color--primary {
    border-color: var(--borderColorPrimary);
}

.border__color--primaryHover {
    border-color: var(--hoverBorderColorPrimary);
}

//// End - Colors


// Start - Font sizes
.fontSize--h1 {
    font-size: $fontSizeH1;
}

.fontSize--h2 {
    font-size: $fontSizeH2;
}

.fontSize--h3 {
    font-size: $fontSizeH3;
}

.fontSize--h4 {
    font-size: $fontSizeH4;
}

.fontSize--h5 {
    font-size: $fontSizeH5;
}

.fontSize--h6 {
    font-size: $fontSizeH6;
}

//// End - Font sizes


// Start - Text transform
.text--capitalize {
    text-transform: capitalize;
}

.text__decoration--strikethrough {
    text-decoration: line-through;
}

//// End - Text transform


// Start - Font weights
.font__weight--extrabold {
    font-weight: $fontWeightExtraBold;
}

.font__weight--bold {
    //font-weight: $fontWeightBold;
    font-family: 'met_bold';
}

.font__weight--semibold {
    //font-weight: $fontWeightSemiBold;
    font-family: 'met_semi_bold';
}

.font__weight--medium {
    //font-weight: $fontWeightMedium;
    font-family: 'met_medium';
}

.font__weight--regular {
    font-weight: $fontWeightRegular;
}

.font__weight--light {
    font-weight: $fontWeightLight;
}

//// End - Font weights


//// Start - Borders & radiuses
.borderRadius--cardElement {
    border-radius: $borderRadiusCardElement;
}

.borderRadius--appWrapper {
    border-radius: $borderRadiusAppWrapper;
}

.borderRadius--small {
    border-radius: $borderRadiusSmall;
}

.borderRadius--large {
    border-radius: $borderRadiusLarge;
}

.border {
    border: 1px solid var(--borderColorPrimary);
}

.borderTop {
    border-top: 1px solid var(--borderColorPrimary);
}

.borderTopLight {
    border-top: 1px solid var(--borderColorPrimaryLight);
}

.borderBottom {
    border-bottom: 1px solid var(--borderColorPrimary);
}

.borderLeft {
    border-left: 1px solid var(--borderColorPrimary);
}

//// End - Borders & radiuses


// Start - Paddings & Margins
.pad--l1 {
    padding: $spaceL1;
}

.pad--l2 {
    padding: $spaceL2;
}

.pad--l3 {
    padding: $spaceL3;
}

.pad--l4 {
    padding: $spaceL4;
}

.pad--l5 {
    padding: $spaceL5;
}

.pad--l6 {
    padding: $spaceL6;
}

.pad--l7 {
    padding: $spaceL7;
}

.pad--l8 {
    padding: $spaceL8;
}

.pad--l9 {
    padding: $spaceL9;
}

.pad--l10 {
    padding: $spaceL10;
}

.padLeft--l1 {
    padding-left: $spaceL1;
}

.padLeft--l2 {
    padding-left: $spaceL2;
}

.padLeft--l3 {
    padding-left: $spaceL3;
}

.padLeft--l4 {
    padding-left: $spaceL4;
}

.padLeft--l5 {
    padding-left: $spaceL5;
}

.padLeft--l6 {
    padding-left: $spaceL6;
}

.padLeft--l7 {
    padding-left: $spaceL7;
}

.padLeft--l8 {
    padding-left: $spaceL8;
}

.padLeft--l9 {
    padding-left: $spaceL9;
}

.padLeft--l10 {
    padding-left: $spaceL10;
}

.padRight--l1 {
    padding-right: $spaceL1;
}

.padRight--l2 {
    padding-right: $spaceL2;
}

.padRight--l3 {
    padding-right: $spaceL3;
}

.padRight--l4 {
    padding-right: $spaceL4;
}

.padRight--l5 {
    padding-right: $spaceL5;
}

.padRight--l6 {
    padding-right: $spaceL6;
}

.padRight--l7 {
    padding-right: $spaceL7;
}

.padRight--l8 {
    padding-right: $spaceL8;
}

.padRight--l9 {
    padding-right: $spaceL9;
}

.padRight--l10 {
    padding-right: $spaceL10;
}

.padTop--l1 {
    padding-top: $spaceL1;
}

.padTop--l2 {
    padding-top: $spaceL2;
}

.padTop--l3 {
    padding-top: $spaceL3;
}

.padTop--l4 {
    padding-top: $spaceL4;
}

.padTop--l5 {
    padding-top: $spaceL5;
}

.padTop--l6 {
    padding-top: $spaceL6;
}

.padTop--l7 {
    padding-top: $spaceL7;
}

.padTop--l8 {
    padding-top: $spaceL8;
}

.padTop--l9 {
    padding-top: $spaceL9;
}

.padTop--l10 {
    padding-top: $spaceL10;
}

.padBottom--l1 {
    padding-bottom: $spaceL1;
}

.padBottom--l2 {
    padding-bottom: $spaceL2;
}

.padBottom--l3 {
    padding-bottom: $spaceL3;
}

.padBottom--l4 {
    padding-bottom: $spaceL4;
}

.padBottom--l5 {
    padding-bottom: $spaceL5;
}

.padBottom--l6 {
    padding-bottom: $spaceL6;
}

.padBottom--l7 {
    padding-bottom: $spaceL7;
}

.padBottom--l8 {
    padding-bottom: $spaceL8;
}

.padBottom--l9 {
    padding-bottom: $spaceL9;
}

.padBottom--l10 {
    padding-bottom: $spaceL10;
}

.marginLeft--l1 {
    margin-left: $spaceL1;
}

.marginLeft--l2 {
    margin-left: $spaceL2;
}

.marginLeft--l3 {
    margin-left: $spaceL3;
}

.marginLeft--l4 {
    margin-left: $spaceL4;
}

.marginLeft--l5 {
    margin-left: $spaceL5;
}

.marginLeft--l6 {
    margin-left: $spaceL6;
}

.marginLeft--l7 {
    margin-left: $spaceL7;
}

.marginLeft--l8 {
    margin-left: $spaceL8;
}

.marginLeft--l9 {
    margin-left: $spaceL9;
}

.marginLeft--l10 {
    margin-left: $spaceL10;
}

.marginRight--l1 {
    margin-right: $spaceL1;
}

.marginRight--l2 {
    margin-right: $spaceL2;
}

.marginRight--l3 {
    margin-right: $spaceL3;
}

.marginRight--l4 {
    margin-right: $spaceL4;
}

.marginRight--l5 {
    margin-right: $spaceL5;
}

.marginRight--l6 {
    margin-right: $spaceL6;
}

.marginRight--l7 {
    margin-right: $spaceL7;
}

.marginRight--l8 {
    margin-right: $spaceL8;
}

.marginRight--l9 {
    margin-right: $spaceL9;
}

.marginRight--l10 {
    margin-right: $spaceL10;
}

.marginTop--l1 {
    margin-top: $spaceL1;
}

.marginTop--l2 {
    margin-top: $spaceL2;
}

.marginTop--l3 {
    margin-top: $spaceL3;
}

.marginTop--l4 {
    margin-top: $spaceL4;
}

.marginTop--l5 {
    margin-top: $spaceL5;
}

.marginTop--l6 {
    margin-top: $spaceL6;
}

.marginTop--l7 {
    margin-top: $spaceL7;
}

.marginTop--l8 {
    margin-top: $spaceL8;
}

.marginTop--l9 {
    margin-top: $spaceL9;
}

.marginTop--l10 {
    margin-top: $spaceL10;
}

.marginTop--l12 {
    margin-top: $spaceL12;
}

.marginBottom--l1 {
    margin-bottom: $spaceL1;
}

.marginBottom--l2 {
    margin-bottom: $spaceL2;
}

.marginBottom--l3 {
    margin-bottom: $spaceL3;
}

.marginBottom--l4 {
    margin-bottom: $spaceL4;
}

.marginBottom--l5 {
    margin-bottom: $spaceL5;
}

.marginBottom--l6 {
    margin-bottom: $spaceL6;
}

.marginBottom--l7 {
    margin-bottom: $spaceL7;
}

.marginBottom--l8 {
    margin-bottom: $spaceL8;
}

.marginBottom--l9 {
    margin-bottom: $spaceL9;
}

.marginBottom--l10 {
    margin-bottom: $spaceL10;
}

.pagePad__x {
    width: 100%;
    max-width: 1300px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;
}

.pagePad__y {
    padding-top: 25px;
    padding-bottom: 25px;
}

.section__marginTop {
    margin-top: $spaceL4;

    @include mobile {
        margin-top: $spaceL3;
    }
}

.section__marginTop--large {
    margin-top: $spaceL5;

    @include mobile {
        margin-top: $spaceL4;
    }
}

//// End - Paddings & Margins


// Start - Others
.cursor--pointer {
    cursor: pointer;
}

.cursor--notAllowed {
    cursor: not-allowed;
}

.width--full {
    width: 100%;
}

.height--full {
    height: 100%;
}

.whitespace--nowrap {
    white-space: nowrap;
}

//// End - Others
