:root {
    --colorPrimary: #000000;
    --colorSecondary: #CED7D2;
    --colorHighlight: #FBAF03;
    --colorLink: #ffffff;
    --colorScore: #92559B;
    --colorReward: #389959;

    --bgColorPrimary: #ffffff;
    --bgColorPrimaryLighter: #F7F8F8;
    --bgColorSecondary: #121212;
    --bgColorTertiary: #373737;
    --bgColorHighlight: #FBAF03;
    --bgColorHighlightDisabled: #664411;

    --borderColorPrimary: #666666;
    --borderColorPrimaryLight: rgba(102, 102, 102, 0.3);
    --hoverBorderColorPrimary: #c9c9c9;

    --notificationBgColor: #F23A3A;

    --errorToastBgColor: #F23A3A;
    --warningToastColor: rgba(0, 0, 0, 0.8);
    --warningToastBgColor: #E1E3E5;
    --successToastBgColor: #52C41A;

    --coinsMissionWidgetBorderColor: 255, 173, 1;
    --cashMissionWidgetBorderColor: 111, 202, 142;
    --merchMissionWidgetBorderColor: 235, 137, 228;

    --missionWidgetBgColor: #1D1D1D;
    --coinsMissionWidgetRewardBgColor: #342D1C;
    --cashMissionWidgetRewardBgColor: #0E2516;
    --merchMissionWidgetRewardBgColor: #602959;

    --missionNotificationsWidgetBg: #2B7443;

    --missionProgressCompletedBg: #2B7443;
    --missionProgressStartedBg: #654601;
    --missionReversedActivityBg: #541A1F;

    --cocktailMissionWidgetBorderColor: 198, 133, 239;
    --cocktailMissionWidgetBgColor: #14AAD6;
    --cocktailMissionWidgetBgGradient: linear-gradient(142.23deg, #521478 13.07%, #2E1240 78.17%);

    --nativeIconsDistanceFromTop: 16px;
    --nativeFooterOverlayHeight: 0;
    --nativeIconsWidth: 32px;
}
