@import "_mixins";
@import "_typography";
@import "_variables";
@import "./icomoon/style.css";

// To disable text selection/highlight on double click
:root {
    color-scheme: light only;
}

// To stop iOS scroll bouncing
html {
    overflow: hidden;
    position: fixed;
    height: 100%;
    height: -webkit-fill-available;
    width: 100%;
    overscroll-behavior: none;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    height: 100%;
    width: 100%;
    overscroll-behavior: none;
}

html,
body {
    color: var(--colorPrimary);
    background-color: var(--bgColorPrimary);
    line-height: 1.2;
}

.main {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: flex-start;
    flex-flow: column;

    .fixedHeader {
        width: 100%;
    }

    .scrollableBody {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: none;
    }
}

// Start - Base Elements
*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    color: var(--colorPrimary);
    text-decoration: none;
}

select {
    cursor: pointer;
}

div[contenteditable="true"] {
    margin: 0;
    padding: 0;
    outline: none;
    min-height: 14px;

    &:focus {
        //border-bottom: 1px dashed var(--colorHighlight);
    }

    &:empty:before {
        content: attr(placeholder);
        pointer-events: none;
        display: block; /* For Firefox */
        color: var(--colorSecondary);
    }
}

.customList {
    margin: 0;
    padding-left: 15px;
}

.ptr__children {
    overflow: hidden auto !important;
}

.swal-button { // Sweet Alert CTAs
    &.swal-button--confirm {
        color: #000;
        background-color: #fbaf03;
    }
}

.swal-title { // Sweet Alert title
    color: #222;
    font-family: met_medium;
    padding: 0;
    font-size: 18px;

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:first-child {
        margin-top: 20px;
    }
}

.swal-text { // Sweet Alert description
    font-size: 13px;
    padding: 0;
    color: rgba(0, 0, 0, .64);
    overflow-wrap: break-word;
    box-sizing: border-box;
}

//// End - Base Elements


// Start - Flexbox & Alignments
.display--flex {
    display: flex;
}

.display__inline--flex {
    display: inline-flex;
}

.flex__flow--wrap {
    flex-flow: wrap;
}

.flex__flow--column {
    flex-flow: column;
}

.flex__justifyContent--start {
    justify-content: flex-start;
}

.flex__justifyContent--center {
    justify-content: center;
}

.flex__justifyContent--end {
    justify-content: flex-end;
}

.flex__justifyContent--between {
    justify-content: space-between;
}

.flex__justifyContent--around {
    justify-content: space-around;
}

.flex__alignItems--start {
    align-items: flex-start;
}

.flex__alignItems--center {
    align-items: center;
}

.flex__alignItems--end {
    align-items: flex-end;
}

.alignSelf--stretch {
    align-self: stretch;
}

.flex__direction--column {
    flex-direction: column;
}

.flex--center--center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex--1 {
    flex: 1;
}

.flex--2 {
    flex: 2;
}

.flex--3 {
    flex: 3;
}

.display--block {
    display: block;
}

.display--inline-block {
    display: inline-block;
}

.text__align--center {
    text-align: center;
}

.text__align--left {
    text-align: left;
}

.text__align--right {
    text-align: right;
}

.twoColumnLayout__container {
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: $spaceL8;

    @include tablet {
        display: block;
    }

    .twoColumnLayout__col2 {
        @include tablet {
            margin-top: $spaceL4;
        }

        @include mobile {
            margin-top: $spaceL3;
        }
    }
}

.position--absolute {
    position: absolute;
}

.position--relative {
    position: relative;
}

.position--static {
    position: static;
}

//// End - Flexbox & Alignments


// Start - Custom Elements
.pageHeading {
    font-size: $fontSizeH3;
    font-weight: $fontWeightMedium;
}

.sectionHeading {
    font-size: 46px;
    font-family: met_bold;

    @include mobile() {
        font-size: 28px;
    }
}

.textAlign--center--mobile {
    @include mobile() {
        text-align: center;
    }
}

.title {
    font-size: $fontSizeH4;
    font-weight: $fontWeightMedium;

    @include mediumDesktop {
        font-size: $fontSizeH5;
    }
}

.subtitle {
    font-size: $fontSizeH5;
    font-weight: $fontWeightRegular;
    color: var(--colorSecondary);

    @include mediumDesktop {
        font-size: $fontSizeH6;
    }
}

.description {
    font-size: $fontSizeH6;
    font-weight: $fontWeightRegular;
    color: var(--colorSecondary);
}

.formElement {
    border-radius: $borderRadiusCardElement;
    font-size: $fontSizeH5;
    padding: 8px 12px;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-color: var(--borderColorPrimary);
    background-color: var(--bgColorSecondary);

    &:active,
    &:focus,
    &:hover {
        border-color: var(--colorHighlight);
    }
}

//// End - Custom Elements


// Start - Custom Properties
.circle {
    border-radius: 50%
}

.circle--l1 {
    width: $spaceL1;
    height: $spaceL1;
    border-radius: 50%;
}

.circle--l3 {
    width: $spaceL3;
    height: $spaceL3;
    border-radius: 50%;
}

.oneLineText {
    word-break: break-all;
    @include limitNumberOfLinesText(1);
}

.twoLinesText {
    word-break: break-word;
    @include limitNumberOfLinesText(2);
}

.threeLinesText {
    word-break: break-word;
    @include limitNumberOfLinesText(3);
}

.disabled {
    pointer-events: none;
}

.opacity--50 {
    opacity: 0.5;
}

.grayscale {
    filter: grayscale(1);
}

.wordBreak {
    word-break: break-word;
}

.fullBgImg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.fullOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overflow--hidden {
    overflow: hidden;
}

.hideScrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: #262626;
    }
    100% {
        background-color: rgba(0, 0, 0, 1);
    }
}

//// End - Custom Properties


.carousel-root.blockCarousel {
    .slider {
        transition: all 50000s !important;
    }
}


h1, h2, h3, h4, h5, h6 {
    margin: 0;
}


table, th, td {
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
}

table {
    th {
        font-family: met_semi_bold;
        padding: $spaceL1;
    }
    th, td {
        text-align: left;

        input,
        textarea {
            outline: 0;
            border: none;
            border-radius: 0;

            &:focus-visible {
                outline: 2px solid blue;
                border-radius: 2px;
                padding: $spaceL2;
            }
        }
    }
}
