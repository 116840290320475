// Start - Device sizes
$maxMobileWidth: 767px;
$maxTabletWidth: 991px;
$maxSmallDesktopWidth: 1200px;
$maxMediumDesktopWidth: 1550px;
// End - Device sizes

// Start - Header, CampaignSelectionModalFooter, SideNavigation Heights & Widths
$headerHeight: 80px;
$userMenuWidth: 208px;
$elementBuilderSideNavigationWidth: 230px;
$elementBuilderPropertiesSidePanel: 430px;
// End - Header, CampaignSelectionModalFooter, SideNavigation Heights & Widths


//Start - Dialog dimensions e.g. Height, Width, Border radius
$profileDialogMaxWidth: 758px;

//End - Dialog dimensions e.g. Height, Width, Border radius

// Start - Font sizes
$fontSizeH1: 40px;
$fontSizeH2: 32px;
$fontSizeH3: 24px;
$fontSizeH4: 16px;
$fontSizeH5: 14px;
$fontSizeH6: 12px;
$fontSizeValidationError: 10px;
// End - Font sizes


// Start - Spacings
$statusBarHeight: 30px;
$spaceL1: 8px;
$spaceL2: 16px;
$spaceL3: 24px;
$spaceL4: 32px;
$spaceL5: 40px;
$spaceL6: 48px;
$spaceL7: 56px;
$spaceL8: 64px;
$spaceL9: 72px;
$spaceL10: 80px;
$spaceL12: 96px;
// End - Spacings


// Start - Font weights
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 600;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
// End - Font weights


// Start - Border radiuses
$borderRadiusCardElement: 12px;
$borderRadiusAppWrapper: 8px;
$borderRadiusSmall: 6px;
$borderRadiusLarge: 24px;
// End - Border radiuses


// Start - Modal width
$rightModalWidth: 432px;
$centerModalMaxWidth: 1000px;
$centerModalMinWidth: 500px;
// End - Modal width


// Start - Custom input
$customInputHeight: 48px;
$customInputPaddingX: 10px;
$customInputPaddingY: 15px;
// End - Custom Input


// Start - Media Queries
@mixin mobile {
    @media only screen and (max-width: #{$maxMobileWidth}) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: #{$maxTabletWidth}) {
        @content;
    }
}

@mixin smallDesktop {
    @media only screen and (max-width: #{$maxSmallDesktopWidth}) {
        @content;
    }
}

@mixin mediumDesktop {
    @media only screen and (max-width: #{$maxMediumDesktopWidth}) {
        @content;
    }
}

@mixin aboveMobile {
    @media only screen and (min-width: #{$maxMobileWidth + 1}) {
        @content;
    }
}

@mixin aboveTablet {
    @media only screen and (min-width: #{$maxTabletWidth + 1}) {
        @content;
    }
}

@mixin aboveMediumDesktop {
    @media only screen and (min-width: #{$maxMediumDesktopWidth + 1}) {
        @content;
    }
}

// End - Media Queries


// Start - Other Mixins & Methods
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        -#{$prefix}-#{$property}: $value;
    }

    #{$property}: $value;
}

@mixin fontFace($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf') format('truetype'),
        url($file-name + '.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

@mixin backgroundGradient($start-color, $end-color, $orientation: 'vertical', $startPos: 0%, $endPos:100%) {
    background: $start-color;

    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color $startPos, $end-color $endPos);
        background: radial-gradient(ellipse at center, $start-color $startPos, $end-color $endPos);
    }
}

@function shadow($hor:1px, $vert:1px, $blur:0, $color:#000, $opacity:1) {
    @return rgba($color, $opacity) $hor $vert $blur;
}

@mixin limitNumberOfLinesText($num:1) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
}

// End - Other Mixins & Methods
